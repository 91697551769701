<mat-toolbar id="main-toolbar" class="mat-toolbar-row mat-toolbar-single-row">
  <app-tabs></app-tabs>
  <span class="spacer"></span>

  <app-menu [style.margin-right]="'2w'"></app-menu>
  <img class="card-image" alt="eu-logo" src="assets/logo-eu.svg" *ngIf="(authorized$ | async) === false"/>
  <button mat-button [disabled]="true">
    <img class="card-image" alt="card-image" src="assets/niedersachsen-klar.jpg" style="max-height: 50px"/>
  </button>
</mat-toolbar>
